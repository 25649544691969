const App = {
  SetBackground: () => {
    $("[setBackground]").each(function () {
      var background = $(this).attr("setBackground");
      $(this).css({
        "background-image": "url(" + background + ")",
        "background-size": "cover",
        "background-position": "center center",
      });
    });
    $("[setBackgroundRepeat]").each(function () {
      var background = $(this).attr("setBackgroundRepeat");
      $(this).css({
        "background-image": "url(" + background + ")",
        "background-repeat": "repeat",
      });
    });
  },
  EqualHeightElement: (el) => {
    let height = 0;
    let thisHeight = 0;
    $(el).each(function () {
      thisHeight = $(this).height();
      if (thisHeight > height) {
        height = thisHeight;
      }
    });
    $(el).height(height);
  },
  ScrollTo: (y) => {
    $("html, body").animate(
      {
        scrollTop: y,
      },
      1000
    );
  },
  Init: () => {
    App.ScrollTo();
    App.SetBackground();
  },
};

function InitSlider() {
  var heroBanner = new Swiper(".hero-banner .swiper-container", {
    speed: 1600,
    slidesPerView: 1,
    spaceBetween: 15,

    loop: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: !0,
      type: "bullets",
    },
  });
  var homeProduct = new Swiper(".home-product .swiper-container", {
    slidesPerView: 3,
    spaceBetween: 13,
    navigation: {
      nextEl: ".home-product .swiper-next",
    },
    breakpoints: {
      1200: {
        slidesPerView: 2,
      },
      576: {
        slidesPerView: 1,
      },
    },
  });
  var ourSlutions = new Swiper(".our-slutions .swiper-container", {
    slidesPerView: 2,
    spaceBetween: 0,
    pagination: {
      el: ".our-slutions .swiper-pagination",
      clickable: true,
      type: "bullets",
    },
    navigation: {
      nextEl: ".our-slutions .swiper-next",
    },
    breakpoints: {
      768: {
        slidesPerView: 1,
      },
    },
  });
  var marketSlide = new Swiper(".market-slide .swiper-container", {
    slidesPerView: 3,
    spaceBetween: 26,
    pagination: {
      el: ".market-slide .swiper-pagination",
      clickable: true,
      type: "bullets",
    },
    loop: true,
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 16,
      },
    },
  });
  var ourAwardsSlide = new Swiper(".our-awards-slide .swiper-container", {
    slidesPerView: 3,
    spaceBetween: 53,
    pagination: {
      el: ".our-awards-slide .swiper-pagination",
      clickable: true,
      type: "bullets",
    },
    loop: true,
    breakpoints: {
      1025: {
        spaceBetween: 33,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 23,
      },
      576: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
    },
  });
  var otherProductSlide = new Swiper(".other-product .swiper-container", {
    slidesPerView: 4,
    spaceBetween: 64,
    autoplay: {
      delay: 7000,
    },
    breakpoints: {
      1025: {
        slidesPerView: 3,
        spaceBetween: 23,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      575: {
        slidesPerView: 1,
      },
    },
  });
  var otherNewsSlide = new Swiper(".other-news .swiper-container", {
    slidesPerView: 3,
    spaceBetween: 64,
    autoplay: {
      delay: 7000,
    },
    breakpoints: {
      992: {
        slidesPerView: 3,
        spaceBetween: 23,
      },
      575: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
    },
  });
  var testimonialSlide = new Swiper(".testimonial .swiper-container", {
    slidesPerView: 1,
    spaceBetween: 15,
    pagination: {
      el: ".testimonial .swiper-pagination",
      clickable: true,
      type: "bullets",
    },
  });
  var galleryThumbs = new Swiper(".product-gallery .gallery-thumbs", {
    spaceBetween: 10,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    direction: "vertical",
    breakpoints: {
      768: {
        direction: "horizontal",
        slidesPerView: 3,
        spaceBetween: 10,
        autoplay: {
          delay: 5000,
        },
      },
    },
    on: {
      slideChangeTransitionStart: function () {
        $(".product-gallery .gallery-thumbs .title").hide(0);
        $(".product-gallery .gallery-thumbs .title")
          .removeClass("animation")
          .removeClass("animate");
      },
      slideChangeTransitionEnd: function () {
        $(".product-gallery .gallery-thumbs .title").show(0);
      },
    },
  });
  var galleryTop = new Swiper(".product-gallery .gallery-top", {
    spaceBetween: 10,
    slidesPerView: 1,
    thumbs: {
      swiper: galleryThumbs,
    },
  });
}

function Headers() {
  $(document).on("click", ".menu-toggle", function () {
    $(this).toggleClass("active");
    $(".header .menu__list").slideToggle(200);
  });
  $(document).on("click", ".toggle-btn", function () {
    $(this).toggleClass("active");
    $(".header").toggleClass("show");
    $(".header .mobile-nav-inner").toggleClass("active");
    $(".back-drop").toggleClass("active");
  });
  $(document).on("click", ".close-btn", function () {
    $(".cart-panel").removeClass("show");
    $(".cart-overlay").removeClass("active");
  });
  $(document).on("click", ".cart-toggle", function () {
    $(".cart-panel").toggleClass("show");
    $(".cart-overlay").addClass("active");
    $(".header .mobile-nav-inner").removeClass("active");
  });
  $(document).on("click", ".mobile-nav-inner li.has-dropdown>a", function () {
    $(this).toggleClass("active").next().slideToggle();
  });
  $(document).on("click", ".back-drop,.cart-overlay", function () {
    $(this).removeClass("active");
    $(".header .mobile-nav-inner").removeClass("active");
    $(".search-wrapper").removeClass("active");
    $(".toggle-btn").removeClass("active");
    $(".cart-panel").removeClass("show");
  });
  $(document).on("click", ".about-mobile-nav", function () {
    $(".about-us .nav-list").toggleClass("active");
  });
  $(".about-us .nav-list li a").on("click", function () {
    $(".about-us .nav-list").removeClass("active");
  });
}
//mapping
function mappings() {
  var moveTop = new MappingListener({
    selector: "header .header__top",
    mobileWrapper: ".mobile-nav-inner",
    mobileMethod: "appendTo",
    desktopWrapper: "header",
    desktopMethod: "prependTo",
    breakpoint: 992,
  }).watch();
  // var moveAccount = new MappingListener({
  //   selector: "header .account",
  //   mobileWrapper: ".mobile-nav-inner",
  //   mobileMethod: "appendTo",
  //   desktopWrapper: "header .header__bottom .right-w .cart",
  //   desktopMethod: "insertBefore",
  //   breakpoint: 992,
  // }).watch();
  var moveSearch = new MappingListener({
    selector: "header .search-wrapper",
    mobileWrapper: ".header .header__bottom .wrapper",
    mobileMethod: "prependTo",
    desktopWrapper: ".header .logo",
    desktopMethod: "insertAfter",
    breakpoint: 992,
  }).watch();
  var moveNav = new MappingListener({
    selector: "header .menu",
    mobileWrapper: ".mobile-nav-inner",
    mobileMethod: "appendTo",
    desktopWrapper: "header .header__bottom .right-w",
    desktopMethod: "insertBefore",
    breakpoint: 992,
  }).watch();
}
function accordion() {
  $(".accordion-item.active").find(".accordion-content").show();
  $(".accordion-heading").on("click", function () {
    if (!$(this).parent().is(".active")) {
      $(this)
        .parent()
        .toggleClass("active")
        .children(".accordion-content")
        .slideToggle(500)
        .parent()
        .siblings(".active")
        .removeClass("active")
        .children(".accordion-content")
        .slideToggle(500);
    } else {
      $(this).parent().toggleClass("active");
      $(this).next().slideToggle(500);
    }
  });
}
function adjustProductAmount() {
  $(".plus").click(function () {
    if ($(this).parents(".amount").find(".input").val() < 10) {
      $(this)
        .parents(".amount")
        .find(".input")
        .val(+$(this).parents(".amount").find(".input").val() + 1);
    }
  });
  $(".minus").click(function () {
    if ($(this).parents(".amount").find(".input").val() > 1) {
      if ($(this).parents(".amount").find(".input").val() > 1)
        $(this)
          .parents(".amount")
          .find(".input")
          .val(+$(this).parents(".amount").find(".input").val() - 1);
    }
  });
}
function parallax() {
  $(".jarallax").jarallax({
    speed: 0.2,
  });
}
function myTabs() {
  $(".my-tabs").each(function () {
    var $myTabs = $(this);

    $myTabs.find(".tab-content").hide().first().show();
    $myTabs.find("ul.tabs-nav li:first").addClass("active").show();
    $myTabs.find("ul.tabs-nav li").click(function () {
      var $this = $(this);

      $this.addClass("active").siblings().removeClass("active");
      $this.parents(".my-tabs").find(".tab-content").hide();
      var activeTab = $this.find("a").attr("href");
      var position = $(this).position();
      scroll = $(".tabs-nav").scrollLeft();
      $(".tabs-nav").animate(
        {
          scrollLeft: scroll + position.left,
        },
        200
      );
      $(activeTab).fadeIn();
      return false;
    });
  });
  $(" .next").click(function (e) {
    e.preventDefault();
    $("li.active").next("li").trigger("click");
  });
  $(" .previous").click(function (e) {
    e.preventDefault();
    $("li.active").prev("li").trigger("click");
  });
}
function fixedNav() {
  var nav = $(".header__bottom");
  var $window = $(window);
  var windowsize = $window.width();
  if (nav.length) {
    var offsetTop = nav.offset().top;
    $(window).on("load scroll", function () {
      if (windowsize >= 992) {
        if ($(window).scrollTop() > offsetTop) {
          nav.addClass("hide");
        } else {
          nav.removeClass("hide");
        }
        if ($(window).scrollTop() > 200) {
          nav.addClass("show");
        } else {
          nav.removeClass("show");
        }
      }
    });
  }
}
function selectUi() {
  $(".custom-select__list").on(
    "click",
    ".custom-select__item.init",
    function () {
      if ($(this).parents(".custom-select").hasClass("open")) {
        $(this).parents(".custom-select").removeClass("open");
        $(this).parent().children(".custom-select__item:not(.init)").hide();
      } else {
        $(".custom-select").removeClass("open");
        $(this).parents(".custom-select").addClass("open");
        $(".custom-select__item:not(.init)").hide();
        $(this).parent().children(".custom-select__item:not(.init)").show();
      }
    }
  );
  var allOptions = $(".custom-select__list").children(
    ".custom-select__item:not(.init)"
  );
  $(".custom-select__list").on(
    "click",
    ".custom-select__item:not(.init)",
    function () {
      allOptions.removeClass("selected");
      $(this).addClass("selected");
      $(this)
        .parents(".custom-select__list")
        .children(".custom-select__item.init")
        .html($(this).html())
        .css("color", "#000");
      $(this)
        .parents(".custom-select__list")
        .children(".custom-select__item:not(.init)")
        .toggle();
      $(this).parents(".custom-select").toggleClass("open");
      $("input#inputInit").val($(this).data("val"));
    }
  );
  var $menu = $(".custom-select");

  $(document).mouseup(function (e) {
    if (!$menu.is(e.target) && $menu.has(e.target).length === 0) {
      $menu.removeClass("open");
      $menu.find(".custom-select__item:not(.init)").hide();
    }
  });
}

function autoComplete() {
  var cate = [
    "Singa Hills, 33 Jalan Singa, 418109",
    "Singapore Post Centre, 0 Singapore Post Centre, 912898",
    "Singapore Post Centre, 0 Singapore Post Centre, 911758",
    "Singapore Post Centre, 0 Singapore Post Centre, 911727",
    "Can’t find your address?",
  ];
  $("#autocomplete").autocomplete({
    source: cate,
  });
}
function productOffset() {
  var headerHeight = $(".header").height();
  $(".product .tabs-nav li").on("click", function () {
    $("html, body")
      .stop()
      .animate(
        {
          scrollTop:
            $(".product .product-categories .tabs-container").offset().top -
            headerHeight,
        },
        500,
        "linear"
      );
  });
}
function buttonHover() {
  $(".deeper-button").each(function () {
    $(this).mouseenter(function (e) {
      var parentOffset = $(this).offset();

      var relX = e.pageX - parentOffset.left;
      var relY = e.pageY - parentOffset.top;
      $(this).find(".hover-effect").css({ left: relX, top: relY });
    });

    $(this).mouseleave(function (e) {
      var parentOffset = $(this).offset();

      var relX = e.pageX - parentOffset.left;
      var relY = e.pageY - parentOffset.top;
      $(this).find(".hover-effect").css({ left: relX, top: relY });
    });
  });
}
//language
function toggleLanguage() {
  $(".language-active").on("click", function (e) {
    $(".language-list").slideToggle(200), e.stopPropagation();
  }),
    $(document).on("click", function (e) {
      !1 === $(e.target).is(".language-wrap") && $(".language-list").hide();
    });
}
function checkWidth() {
  var $window = $(window);
  var windowsize = $window.width();
  if (windowsize >= 1440) {
    $(".header .menu__list").removeAttr("style");
  }
  if (windowsize < 992) {
    $(".header .menu__list").removeAttr("style");
  }
  if (windowsize >= 768) {
    $(".footer .menu-list").removeAttr("style");
    $(".module-filter .filter-list").removeAttr("style");
  }
}
function countUp() {
  $(".counter").each(function () {
    var $this = $(this),
      countTo = $this.attr("data-count");
    $({ countNum: $this.text() }).animate(
      {
        countNum: countTo,
      },
      {
        duration: 1500,
        easing: "linear",
        step: function () {
          $this.text(Math.floor(this.countNum));
        },
        complete: function () {
          $this.text(this.countNum);
        },
      }
    );
  });
}
function scrollSection() {
  if ($(".about-us").length) {
    var Nav = $(".about-nav"),
      NavTopPosition = Nav.offset().top,
      contentSections = $(".section-scroll");

    var heightNav = $(".about-nav").innerHeight();
    var heightHeader = $(".header__bottom").innerHeight();
    $(window).on("scroll", function () {
      if ($(window).scrollTop() > NavTopPosition) {
        //fix about navigation
        Nav.addClass("fixed-hide");
      } else {
        Nav.removeClass("fixed-hide");
      }
      if ($(window).scrollTop() > NavTopPosition + 50) {
        Nav.addClass("fixed-show");
      } else {
        Nav.removeClass("fixed-show");
      }
      //on desktop - update the active link in the about fixed navigation
      updateNavigation();
    });
    function updateNavigation() {
      contentSections.each(function () {
        var actual = $(this),
          actualHeight =
            actual.height() +
            parseInt(actual.css("paddingTop").replace("px", "")) +
            parseInt(actual.css("paddingBottom").replace("px", "")),
          actualAnchor = Nav.find('a[href="#' + actual.attr("id") + '"]');
        if (
          actual.offset().top - heightNav - heightHeader <=
            $(window).scrollTop() + 10 &&
          actual.offset().top + actualHeight - heightNav - heightHeader >
            $(window).scrollTop()
        ) {
          actualAnchor.parent().addClass("active");
        } else {
          actualAnchor.parent().removeClass("active");
        }
      });
    }
    //smooth scrolling when clicking on the about navigation items
    Nav.find("ul a").on("click", function (event) {
      event.preventDefault();
      var target = $(this.hash);
      $("body,html").animate(
        {
          scrollTop: target.offset().top - heightNav - heightHeader + 10,
        },
        600
      );
    });
  }
}
function sidebarDropdown() {
  //mega menu
  $(document).on("click", ".sidebar-menu .sidebar-text", function () {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $(this).siblings(".menu-sub").slideUp(200);
    } else {
      $(".sidebar-menu .sidebar-text").removeClass("active");
      $(this).addClass("active");
      $(".menu-sub").slideUp(200);
      $(this).siblings(".menu-sub").slideDown(200);
    }
  });
}
$(window).resize(checkWidth).trigger("resize");
$(window).resize(fixedNav).trigger("resize");
$(document).ready(function () {
  App.Init();
  InitSlider();
  checkWidth();
  Headers();
  fixedNav();
  toggleLanguage();
  mappings();
  adjustProductAmount();
  accordion();
  selectUi();
  scrollSection();
  parallax();
  myTabs();
  productOffset();
  autoComplete();
  buttonHover();
  sidebarDropdown();
  countUp();
  // AOS.init({
  //   once: true,
  //   duration: 1000, // values from 0 to 3000, with step 50ms
  //   easing: "ease", // default easing for AOS animations
  // });
});
